<template>
  <div class="home">
    <h1 class="mt-8">The Holy Quran Full - القرآن الكريم كاملاً</h1>
    <list class="mt-8" />
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/components/list.vue";

export default {
  name: "Home",
  components: {
    list,
  },
};
</script>
