<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="6" v-for="item in playlists" :key="item.id">
        <v-card @click="openPlaylist(item)">
          <v-card-title>{{ item.name }}</v-card-title>
          <v-card-text>
            <h2 class="text-end">{{ item.ar_name }}</h2>
          </v-card-text>

          <!-- <v-img :src="item.img"></v-img> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
name: "list",
data() {
    return {
        playlists: [
            {
                id: "PLAK6cxu4bneUsyRHbDUVYrDtaEX8oWsZ5",
                name: "Abdul Basit Abdul Samad ",
                ar_name: "عبد الباسط عبد الصمد ",
            },
            {
                id: "PLAK6cxu4bneUQkOm3LEURXFVuoD6vq_PD",
                name: "Maher Al Mueaqly",
                ar_name: "ماهر المعيقلي",
            },
              {
                id: "PLAK6cxu4bneV4_OaryTE1iJqk_rEt_q4b",
                name: "Saad al-Ghamdi",
                ar_name: "سعد الغامدي",
            },
            {
                id: "PLAK6cxu4bneWpUy05OvWzkdARsOLYHp-8",
                name: "Mishary Al Afasy ",
                ar_name: "مشارى راشد العفاسى",
            },
          
        ],
    };
},
mounted() {
    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    this.playlists=this.playlists.sort(compare);
},
methods: {
    openPlaylist(item) {
        this.$router.push({ path: `playlist/${item.id}/${item.name}/${item.ar_name}` })
    }
},
props: {},
computed: {},
};
</script>
